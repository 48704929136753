.play-button {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 50px;
    cursor: pointer;
    z-index: 1000;
    border: 4px solid deeppink;
}

.play-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
}